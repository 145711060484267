import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, concatMap, finalize, map, tap } from 'rxjs/operators';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { AccountsApiService, SecurityApiService, UaOAuthService } from '@unleashed/api/account';
import { SignUpUser, User } from '@unleashed/models/account';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private unknownErrorMessage = 'Unknown error, please try again';

  private user = new BehaviorSubject<User>({ loggedIn: false });
  private accessToken = new BehaviorSubject<string>('');
  private authInProgress = new BehaviorSubject<boolean>(false);
  private authError = new BehaviorSubject<string | undefined>(undefined);
  private registrationToken?: string;
  private clientId: string;
  private dummyClientSecret: string;
  private scope: string;

  constructor(
    @Inject(APP_OPTIONS) private appOptions: AppOptions,
    private http: HttpClient,
    private oauthService: UaOAuthService,
    private accountsApi: AccountsApiService,
    private securityApi: SecurityApiService
  ) {
    this.clientId = appOptions.auth.clientId;
    this.dummyClientSecret = appOptions.auth.clientSecret;
    this.scope = [...appOptions.auth.scopes, ...['offline_access', 'openid', 'profile', 'accounts', 'payments']].join(' ');
    this.oauthService.configure({
      clientId: this.clientId,
      dummyClientSecret: this.dummyClientSecret,
      issuer: appOptions.auth.issuer,
      scope: this.scope,
      oidc: false
    });
  }

  initialize(): Observable<User> {
    return from(this.oauthService.loadDiscoveryDocument())
      .pipe(
        concatMap(() => {
        return this.loadUser();
      }));
  }

  private loadUser(): Observable<User> {
    const loggedIn = this.oauthService.hasValidAccessToken();

    if (!loggedIn) {
      this.user.next({loggedIn});
      return of ({loggedIn});
    }

    return from(this.oauthService.loadUserProfile())
      .pipe(
        concatMap(userInfo => {
          return this.getOrRefreshAccessToken()
            .pipe(
              map(token => ({ userInfo, token }))
            );
        }),
        concatMap(userToken => {
          return this.accountsApi.getUser(userToken.userInfo.account_id, userToken.token)
            .pipe(
              tap(user => {
                this.user.next({...user, loggedIn});
              }));
        }),
        catchError(err => {
          this.logout();
          return of({loggedIn: false});
        })
      );
  }

  private getOrRefreshAccessToken(): Observable<string> {
    if (!this.accessToken.value) {
      return from(this.oauthService.refreshToken())
        .pipe(
          tap(response => {
            this.accessToken.next(response.access_token);
          }),
          map(response => response.access_token),
         );
    } else {
      return of(this.accessToken.value);
    }
  }

  authorizeUser(username: string, password: string): Observable<User> {
    return from(this.oauthService.fetchTokenUsingPasswordFlow(username, password))
      .pipe(
        concatMap(
          () => {
            this.authError.next(undefined);
            this.accessToken.next(this.oauthService.getAccessToken());
            return this.loadUser();
          }
        ),
        catchError(err => {
          if (err.error?.error_description) {
            this.authError.next(err.error.error_description);
          } else {
            this.authError.next(this.unknownErrorMessage);
          }
          return of({loggedIn: false});
        }));
  }

  impersonateUser(token: string): void {
    this.oauthService.logOut();
    this.oauthService.fetchTokenUsingImpersonationToken(token)
      .then(response => {
        this.accessToken.next(response.access_token);
        this.user.next({
          loggedIn: true,
          impersonated: true});
      });
  }

  logout(): void {
    this.oauthService.logOut();
    this.accessToken.next('');
    this.user.next({loggedIn: false});
  }

  getUser(): Observable<User> {
    return this.user.asObservable();
  }

  getAccessToken(): Observable<string> {
    return this.accessToken.asObservable();
  }

  setInProgress(value: boolean): void {
    return this.authInProgress.next(value);
  }

  getInProgress(): Observable<boolean> {
    return this.authInProgress.asObservable();
  }

  getError(): Observable<string | undefined> {
    return this.authError.asObservable();
  }

  private createAccount(signUp: SignUpUser): Observable<User> {
    if (!this.registrationToken) {
      return this.accountsApi.create({
        emailAddress: signUp.username,
        firstName: signUp.firstName,
        lastName: signUp.lastName,
        phoneNumber: signUp.phoneNumber,
        address: { zipCode: signUp.zipCode, format: 'us', country: 'US' },
      }).pipe(
        concatMap(registration => {
          this.registrationToken = registration.registrationToken;
          return this.securityApi.register(signUp.username, signUp.password, registration.registrationToken);
        })
      );
    } else {
      return this.securityApi.register(signUp.username, signUp.password, this.registrationToken);
    }
  }

  register(signUp: SignUpUser): Observable<User> {
    return this.createAccount(signUp)
      .pipe(
        concatMap(account => {
          return this.authorizeUser(signUp.username, signUp.password);
        }),
        catchError(err => {
          if (err.error?.validationItems?.length) {
            this.authError.next(err.error.validationItems[0].message);
          } else if (err.error?.message) {
            this.authError.next(err.error.message);
          } else {
            this.authError.next(this.unknownErrorMessage);
          }
          return of ({loggedIn: false});
        }),
        finalize(() => {
          this.registrationToken = undefined;
        })
      );
  }

  update(user: User): Observable<User> {
    return this.accountsApi.update(user, this.accessToken.value)
      .pipe(
        tap(u => {
          this.user.next({...u, loggedIn: true});
        }));
  }

  reset(): void {
    this.authError.next(undefined);
  }
}
