<div class="mt-4" *ngIf="!error">
  <ua-nav-bar (backClicked)="back()" (nextClicked)="next()" [nextDisabled]="!timeslotSelected"></ua-nav-bar>

  <ua-call-to-action *ngIf="booking" class="text-center" template="Choose a Time for {0} Party" [booking]="booking"></ua-call-to-action>

  <ua-timeslots-date (dateChanged)="updateDate($event)" (minPrice)="updateMinPrice($event)"></ua-timeslots-date>

  <p class="fst-italic disclaimer text-center" *ngIf="slotsLoaded && timeslots.length">
    *Prices may vary by time. Scroll to see more.
  </p>

  <div *ngIf="!timeslots.length && slotsLoaded" class="text-center mt-3">
    <h3>Sorry, no times available for the selected date</h3>
  </div>

  <ua-skeleton-card *ngIf="!slotsLoaded" [count]="3"></ua-skeleton-card>
  <div *ngFor="let slot of timeslots; let i=index">
    <div class="card mt-3 shadow {{slot.partyThemeColorCode ? '' : 'border-primary'}} border-2 rounded-5" [ngStyle]="{'border-color':slot.partyThemeColorCode}">
      <div class="card-body d-flex">
        <div class="d-flex flex-column w-100 me-3">
          <h5>{{slot.startTime | time | date:'shortTime'}} - {{slot.endTime | time | date:'shortTime'}}</h5>
          <div *ngIf="slot.partyThemeId === this.hangoutsGrouped[slot.startTime]?.partyThemeId" 
              class="{{slot.partyThemeColorCode ? '' : 'text-primary'}}">
            <strong>{{this.hangoutsGrouped[slot.startTime]?.name}}</strong>
            <sup *ngIf="this.hangoutsGrouped[slot.startTime]?.amenities?.length">
              <button class="btn btn-link btn-sm p-0" (click)="showAmenitiesModal(this.hangoutsGrouped[slot.startTime])">
                <fa-icon [icon]="faInfoCircle"></fa-icon>
              </button>
            </sup>
          </div>
          <div *ngIf="slot.partyThemeId !== 1" class="fw-bold {{slot.partyThemeColorCode ? '' : 'text-primary'}}"
               [ngStyle]="{'color':slot.partyThemeColorCode}">{{ slot.partyTheme }}</div>
          <div class="d-flex justify-content-between mt-auto">
            <ua-crowd-meter class="ms-2 mt-2" [levelId]="slot.crowdLevelId"></ua-crowd-meter>
            <small *ngIf="slot.available <= 3" class="align-self-end {{slot.partyThemeColorCode ? '' : 'text-primary'}} mb-1"
             [ngStyle]="{'color':slot.partyThemeColorCode}">
              <strong>Only {{slot.available}} part{{slot.available > 1 ? 'ies' : 'y'}} left!</strong>
            </small>
          </div>
        </div>
        <ua-card-pricing id="card-{{i}}"
                         class="ms-auto"
                         [price]="slot.displayPrice"
                         [quantity]="slot.quantity"
                         [selected]="slot.selected"
                         (selectClicked)="toggleSelection(i)"
                         [showSign]="true"
                         [style]="'showGuests'"
                         [imageUrl]="slot.partyThemeImageUrl">
        </ua-card-pricing>
      </div>
    </div>
  </div>
  <div class="py-2">
    <ua-nav-bar (backClicked)="back()" (nextClicked)="next()" [nextDisabled]="!timeslotSelected"></ua-nav-bar>
  </div>

  <ua-start-over></ua-start-over>

</div>

<ua-error *ngIf="error" [errorCode]="error"></ua-error>

<div id="amenitiesModalTimeSlots" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body p-4">
        <h1 class="text-capitalize fs-1 fw-bold text-primary">Includes: </h1>
      <ul class="list-unstyled">
        <li *ngFor="let a of amenities"><h5><fa-icon class="text-primary me-2" [icon]="faCheck"></fa-icon>{{ a }}</h5></li>
      </ul>
      </div>
      <div class="align-self-center">
          <button class="btn btn-sm mt-2 mb-2 btn-primary" data-bs-dismiss="modal">Dismiss</button>
      </div>
    </div>
  </div>
</div>
