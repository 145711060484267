import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { faCalendarAlt, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IAngularMyDpOptions, IMyDate, IMyStyles } from 'angular-mydatepicker';
import {
  maxDateValidator,
  minDateValidator,
  phoneNumberValidator,
  selectOptionValidator
} from '@unleashed/common/validators';
import { BusyService } from '@unleashed/services/common';
import { BookingConfiguration, Booking, GuestOfHonor, NavigationSteps } from '@unleashed/models/booking';
import { AuthorizationService } from '@unleashed/services/account';
import { BookingSessionService } from '@unleashed/services/booking';
import { Park } from '@unleashed/models/park';
import { getInputDate } from '@unleashed/common/utils';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ua-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  faPlus = faPlus;
  faMinus = faMinus;
  faCalendarAlt = faCalendarAlt;
  bookingForm: FormGroup;
  park?: Park | null;
  configuration?: BookingConfiguration;
  formDirty = false;
  today = this.myDateFromDate(new Date());
  booking?: Booking;
  error?: number;
  isPreferredTimeDropdownRadio?: boolean;
  isParentGuardianFieldsNotRequired?: boolean;

  stylesData: IMyStyles = {
    selector: 'dp1',
    styles: `.dp1 .myDpPrevMonth,
             .dp1 .myDpNextMonth {
                color: #333;
             }
             .dp1 .myDpDisabled {
                color: #777;
                background: transparent;
                text-decoration: line-through;
             }`
  };

  dateOfBirthOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mm/dd/yyyy',
    firstDayOfWeek: 'su',
    disableSince: this.today,
    sunHighlight: false,
    stylesData: this.stylesData
  };

  preferredDateOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mm/dd/yyyy',
    firstDayOfWeek: 'su',
    disableUntil: this.today,
    sunHighlight: false,
    stylesData: this.stylesData
  };

  constructor(
    private authService: AuthorizationService,
    private formBuilder: FormBuilder,
    private sessionService: BookingSessionService,
    private busyService: BusyService,
    private route: ActivatedRoute,
  ) {
    this.bookingForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.busyService.set('start', true);
    this.setQueryParams();
    combineLatest([
      this.sessionService.getSession(),
      this.sessionService.getConfig(),
      this.sessionService.getBooking()
        .pipe(take(1))
    ])
      .subscribe(
        ([session, config, booking]) => {
          this.park = session.park;
          this.setConfig(config);
          this.setBooking(booking);
          this.busyService.set('start', false);
        },
        err => {
          this.error = err.status;
          this.busyService.set('start', false);
        });


    this.authService.getUser()
      .subscribe(user => {
        if (user.loggedIn) {
          this.bookingForm.controls['contactFirstName'].setValue(user.firstName ?? '');
          this.bookingForm.controls['contactLastName'].setValue(user.lastName ?? '');
          this.bookingForm.controls['phoneNumber'].setValue(user.phoneNumber ?? '');
        }
      },
      err => {
        this.error = err.status;
        this.busyService.set('start', false);
      });
  }

  setConfig(config: BookingConfiguration): void {
    this.configuration = config;
    const minBookingDate = new Date(this.today.year, this.today.month - 1, this.today.day + (config?.minAdvanceDays ?? 2) - 1);
    const maxAdvanceDate = new Date(this.today.year, this.today.month - 1, this.today.day + (config?.maxAdvanceDays ?? 60));
    this.preferredDateOptions.disableUntil = this.myDateFromDate(minBookingDate);
    this.preferredDateOptions.disableSince = this.myDateFromDate(maxAdvanceDate);
    this.busyService.set('start', false);
  }

  setBooking(booking: Booking): void {
    this.booking = booking;
    this.bookingForm = this.formBuilder.group({
      guestsOfHonor: this.formBuilder.array([]),
    });

    booking.guestsOfHonor.forEach(g => {
      this.addGuestOfHonorGroup(booking.isDefault ? undefined : g);
    });
    this.bookingForm.addControl('preferredDate', this.formBuilder.control(booking.isDefault
      ? ''
      : {
        isRange: false,
        singleDate: {jsDate: new Date(booking.preferredDate)}
      }, Validators.required));
    this.bookingForm.addControl('preferredTime', this.formBuilder.control(booking.preferredTimeId ? String(booking.preferredTimeId) : (this.isPreferredTimeDropdownRadio ? '1' : 0), selectOptionValidator()));
    this.bookingForm.addControl('requiresHandicap', this.formBuilder.control(booking.requiresHandicap ?? false, Validators.required));
    this.bookingForm.addControl('contactFirstName', this.formBuilder.control(booking.contactFirstName ?? '', this.isParentGuardianFieldsNotRequired ? null : Validators.required));
    this.bookingForm.addControl('contactLastName', this.formBuilder.control(booking.contactLastName ?? '', this.isParentGuardianFieldsNotRequired ? null : Validators.required));
    this.bookingForm.addControl('phoneNumber', this.formBuilder.control(booking.phoneNumber ?? '', phoneNumberValidator()));
  }

  next(): void {
    this.formDirty = true;
    if (!this.bookingForm.valid || !this.park?.id || !this.booking) {
      return;
    }

    this.busyService.set('start-save', true);

    const booking: Booking = {
      ...this.booking,
      parkId: this.park?.id,
      guestsOfHonor: this.bookingForm.value.guestsOfHonor.map((m: any) => ({
        firstName: m.firstName,
        lastName: m.lastName,
        dateOfBirth: getInputDate(m.dateOfBirth)
      })),
      contactFirstName: this.bookingForm.value.contactFirstName,
      contactLastName: this.bookingForm.value.contactLastName,
      phoneNumber: this.bookingForm.value.phoneNumber,
      preferredDate: this.bookingForm.value.preferredDate.singleDate?.jsDate,
      preferredTimeId: this.bookingForm.value.preferredTime,
      requiresHandicap: this.bookingForm.value.requiresHandicap
    };

    this.sessionService.upsertCart(booking)
      .subscribe(
        _ => {
          this.sessionService.navigateToStep(NavigationSteps.Packages);
          this.busyService.set('start-save', false);
        },
        err => {
          this.error = err.status;
          this.busyService.set('start-save', false);
        });
  }

  get guestsOfHonorFormArray(): FormArray {
    return this.bookingForm.controls.guestsOfHonor as FormArray;
  }

  get guestsOfHonorFormGroups(): FormGroup[] {
    return this.guestsOfHonorFormArray.controls as FormGroup[];
  }

  get guestsOfHonorCount(): number {
    return this.guestsOfHonorFormArray.length;
  }

  get maxGuestsOfHonor(): number {
    return this.configuration?.maxGuestsOfHonor ?? 2;
  }

  addGuestOfHonorGroup(guestOfHonor?: GuestOfHonor): void {
    if (this.guestsOfHonorCount > this.maxGuestsOfHonor) {
      return;
    }

    const minDateOfBirth = new Date();
    minDateOfBirth.setFullYear(minDateOfBirth.getFullYear() - 120);

    const group = this.formBuilder.group({
      firstName: [guestOfHonor?.firstName ?? '', Validators.required],
      lastName: [guestOfHonor?.lastName ?? '', Validators.required],
     dateOfBirth: [
        guestOfHonor?.dateOfBirth ? `${(guestOfHonor?.dateOfBirth.getMonth() + 1).toString().padStart(2, '0')}/${(guestOfHonor?.dateOfBirth.getDate()).toString().padStart(2, '0')}/${guestOfHonor?.dateOfBirth.getFullYear()}` : '',
        [
          Validators.required,
          maxDateValidator(new Date(this.today.year, this.today.month - 1, this.today.day)),
          minDateValidator(minDateOfBirth)
        ]]
    });

    this.guestsOfHonorFormArray.push(group);
  }

  removeGuestOfHonorGroup(): void {
    if (this.guestsOfHonorCount === 1) {
      return;
    }

    this.guestsOfHonorFormArray.removeAt(this.guestsOfHonorCount - 1);
  }

  myDateFromDate(date: Date): IMyDate {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  setQueryParams(): void {
    this.isPreferredTimeDropdownRadio = Boolean((this.route.snapshot.queryParamMap.get('radio') ?? 'false')  === 'true');
    this.isParentGuardianFieldsNotRequired = Boolean((this.route.snapshot.queryParamMap.get('pg-req') ?? 'true' ) === 'false');
    localStorage.setItem('bookingQueries', JSON.stringify(this.route.snapshot.queryParamMap));
  }
}
